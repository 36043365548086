@import "../../scss/variables/_index.scss";

.section_wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  & + & {
    margin-top: 32px;
  }

  @media (max-width: $size-mobile) {
    & + & {
      margin-top: 24px;
    }
  }
}

.form_wrapper {
  padding: 32px 0;
}

.form_error {
  flex: 1 100%;
  font-family: $font-face-hind;
  color: $color-red;
  background-color: lighten($color-red, 45);
  text-align: center;
  padding: 16px;
  margin-bottom: 32px;
}

.form_success {
  flex: 1 100%;
  text-align: center;
  font-family: $font-face-hind;
  font-size: $font-size-m;
  line-height: 1.5;
  background: lighten($color-green, 35);
  padding: 24px 16px;
  margin-top: 48px;
  border-top: 5px solid $color-green;
}

.form_success-icon {
  font-size: $font-size-h2;
  color: $color-green;
  margin-bottom: 16px;
}

.button_wrapper {
  justify-content: flex-end;
}

.input_label {
  flex: 1 100%;
  font-family: $font-face-hind;
  font-size: $font-size-m;
  font-weight: 600;
  color: $color-black;
}

.input_label-thin {
  font-size: $font-root;
  font-weight: 300;
  color: #c2c2c2;
}

.input_label-block {
  flex: 1 30%;
  font-family: $font-face-open;
  font-size: $font-size-m;
  font-weight: 600;
  color: $color-black;

  @media (max-width: $size-mobile-landscape) {
    flex: 1 100%;
    padding-bottom: 12px;
  }

  @media (max-width: $size-mobile) {
    font-size: $font-root;
  }
}

.input_label-top {
  align-self: flex-start;
  padding-top: 16px;
}

.input {
  flex: 1 100%;
  margin-top: 8px;
}

.input_file {
  flex: 1 100%;
  width: 100%;
  margin-top: 8px;
  background: $color-bg-grey;
  padding: 12px 16px;
  border-radius: 4px;
}

.inputfile-outer {
  position: relative;
  background-color: $color-white;
  border: dashed 1px $color-purple-light;
  border-radius: 4px;

  & + & {
    margin-top: 16px;
  }

  &:hover {
    cursor: pointer;
  }
}

.input_block {
  flex: 1 70%;

  @media (max-width: $size-mobile-landscape) {
    flex: 1 100%;
  }
}

.button_block {
  display: flex;
  width: 70%;

  @media (max-width: $size-mobile-landscape) {
    width: 100%;
  }
}

.button_full {
  display: flex;
  flex: 1 100%;
}
