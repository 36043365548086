@import "../../../scss/variables/_index.scss";

$input-default-height: 64px;
$input-mobile-height: 44px;

input {
  -webkit-appearance: none;
  border-radius: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.input_wrapper {
  display: flex;
  position: relative;
  overflow: hidden;
}

.input {
  flex: 1 100%;
  height: $input-default-height;
  background-color: $color-white;
  font-family: $font-face-open;
  font-size: $font-root;
  border-radius: 4px;
  border: solid 1px #dedede;
  padding: 0 16px;

  &:focus {
    outline: none;
    border: solid 1px $color-purple;
  }

  &::placeholder {
    color: #c2c2c2;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: #c2c2c2;
  }

  &::-ms-input-placeholder {
    color: #c2c2c2;
  }

  @media (max-width: $size-mobile) {
    height: $input-mobile-height;
  }
}

.textarea {
  padding: 16px;
  resize: none;
  min-height: 200px;
}

.select {
  flex: 1 100%;
  height: $input-default-height;
  background-color: $color-white;
  font-family: $font-face-open;
  font-size: $font-root;
  border-radius: 4px;
  border: solid 1px #dedede;
  padding: 0 16px;
  padding-right: 48px;

  &:focus {
    outline: none;
    border: solid 1px $color-purple;
  }

  @media (max-width: $size-mobile) {
    height: $input-mobile-height;
  }
}

.select--default {
  color: $color-grey-breadcrumbs;
}

.select_arrow {
  position: absolute;
  top: 50%;
  right: 24px;
  transform: translateY(-50%);
  font-size: $font-root;
}

.inputfile {
  visibility: hidden;
  opacity: 0;
  border: none;
  width: 100%;
}

.inputfile_label {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  padding: 0 16px;
  font-family: $font-face-hind;
  font-size: $font-size-m;
  font-weight: 600;
  color: $color-purple-light;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
}

.inputfile_icon {
  align-self: center;
  margin-right: 12px;
  font-size: $font-size-m;
}

.inputfile_type {
  font-family: $font-face-open;
  font-size: $font-root;
  color: #aaa3b8;
  margin-top: 12px;
}

// Error Styling
// ==============================================
.input--error {
  border: solid 1px $color-red;
}

.input_message {
  display: block;
  width: 100%;
  font-family: $font-face-hind;
  font-size: $font-root;
  color: $color-red;
  text-align: right;
  padding: 8px 0;

  @media (max-width: $size-mobile) {
    font-size: $font-size-s;
  }
}
