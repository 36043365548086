$color-white: #fff;
$color-bg-grey: #fcfcfc;
$color-white-footer: #c5c5cc;
$color-cp-footer: #656277;
$color-light-grey: #e5e5e5;
$color-black: #212b34;
$color-grey-breadcrumbs: #72748e;
$color-grey-bg: #f9f9f9;
$color-dark-grey: #4a5259;
$color-purple: #8376c0;
$color-purple-bg: #f7f6fb;
$color-font-purple: #656277;
$color-link-purple: #363fc3;
$color-dots-purple: #3b339e;
$color-purple-menu: #433ab1;
$color-purple-light: #5252b7;
$color-purple-dark: #171133;
$color-green: #65c98f;
$color-red: #bd3030;
