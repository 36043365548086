@import "../../scss/variables/_index.scss";

.product_wrapper {
  display: flex;
  flex-wrap: wrap;
  align-self: flex-start;
  flex: 1 30%;
  width: 30%;
  max-width: 33.33%;
  margin-left: 16px;
  margin-bottom: 32px;

  @media (max-width: $size-mobile) {
    flex: 1 45%;
    width: 45%;
    max-width: 50%;
    margin-left: 10px;
  }
}

.product_wrapper--img {
  flex: 1 100%;
  position: relative;

  &::before {
    content: "";
    display: block;
    padding-top: (296 / 296) * 100%;
  }
}

.product_oos {
  position: relative;
  display: block;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
  }

  a {
    pointer-events: none;
  }
}

.product_oos--label {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 170px;
  max-height: 56px;
  font-family: $font-face-hind;
  font-size: $font-size-l;
  font-weight: 700;
  color: $color-white;
  background: $color-red;
  z-index: $z-index-1;

  @media (max-width: $size-tablet) {
    max-width: 80%;
    max-height: 48px;
    font-size: $font-size-m;
  }

  @media (max-width: $size-mobile) {
    max-height: 32px;
    font-size: $font-root;
  }
}

.product_img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.product_info {
  flex: 1 100%;
  padding: 24px 0;
}

.product_name {
  font-family: $font-face-rubik;
  font-size: $font-size-ml;
  font-weight: 500;
  color: $color-black;
  margin-bottom: 8px;

  @media (max-width: $size-mobile) {
    font-size: $font-root;
  }
}

.product_desc {
  font-family: $font-face-hind;
  font-size: $font-root;
  line-height: 1.3;
  color: $color-grey-breadcrumbs;
  max-width: 80%;

  @media (max-width: $size-mobile) {
    font-size: $font-size-xs;
  }
}

.related_products {
  flex: 1 25%;
  white-space: normal;

  @media (max-width: $size-tablet-sm) {
    display: inline-block;
  }
}
