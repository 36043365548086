@import "../../scss/variables/_index.scss";

.sidebar_wrapper {
  flex: 1 30%;
  padding-left: 120px;

  @media (max-width: $size-tablet) {
    padding-left: 60px;
  }

  @media (max-width: 720px) {
    flex: 1 100%;
    padding: 0 16px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e5e5e5;
  }
}

.sidebar_title {
  margin: 42px 0;
}

.sidebar_menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar_item {
  font-family: $font-face-open;
  font-size: $font-size-l;
  color: $color-dark-grey;

  & + & {
    margin-top: 20px;
  }
}

.sidebar_item-active {
  font-weight: 700;
  color: $color-purple-light;

  span {
    border-bottom: 1px dashed $color-purple-light;
  }
}

// Product Menu Mobile
// =====================================================
.sidebar_product {
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  z-index: $z-index-3;
  background: $color-white;
  padding-top: 24px;
}

.product_active {
  position: relative;
  margin-top: 24px;
  flex: 1 100%;
  height: 56px;
  background-color: $color-white;
  font-family: $font-face-hind;
  font-size: $font-size-l;
  line-height: 56px;
  border-radius: 4px;
  border: solid 1px #dedede;
  padding: 0 16px;
  padding-right: 56px;
}

.select_arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-45%);
  font-size: $font-size-m;
}

.product_menu {
  border-radius: 4px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.06);
  padding: 4px 0;
}

.product_category {
  font-family: $font-face-hind;
  font-size: $font-size-l;
  list-style-type: none;
  padding: 0 16px;
}

.product_subcategory {
  list-style-type: none;
  font-family: $font-face-hind;
  font-size: $font-size-l;
  padding: 8px 24px;
}

.product_subcategory-list {
  & + & {
    margin-top: 8px;
  }
}

.subcategory_link {
  color: $color-black;
}
