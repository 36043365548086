@import "../../scss/variables/_index.scss";

.main_slider-wrapper {
  display: block;
  position: relative;
  width: 100%;
}

.product_images {
  position: relative;
  width: 100%;

  &::before {
    content: "";
    display: block;
    padding-top: (426 / 426) * 100%;
  }
}

.product_img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
}
