@import "../../../scss/variables/_index.scss";

.modal__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: $z-index-10;
}

.modal__close {
  position: absolute;
  top: 32px;
  right: 32px;
  z-index: $z-index-2;

  &:active,
  &:focus {
    outline: none;
  }
}

.modal__close-icon {
  font-size: $font-size-h2;
}
