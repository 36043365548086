// Homepage Feature
// ----------------------------------------------
.main_wrapper--features {
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: $size-tablet-m) {
    padding: 0 20px;
  }
}

.mfeature_wrapper {
  display: flex;
  flex: 1 30%;
  background: $color-purple-bg;
  padding: 42px 24px;
  border-radius: 6px;

  & + & {
    margin-left: 45px;
  }

  @media (max-width: 1200px) {
    & + & {
      margin-left: 20px;
    }
  }

  @media (max-width: $size-tablet) {
    padding: 24px 8px;
  }

  @media (max-width: $size-tablet-m) {
    flex: 1 100%;
    padding: 42px 24px;

    & + & {
      margin-top: 20px;
      margin-left: 0;
    }
  }

  @media (max-width: $size-mobile) {
    padding: 24px 8px;
  }
}

.mfeature_icon {
  flex: 1 20%;
  align-self: center;
  margin-right: 12px;
  max-width: 72px;
  max-height: 72px;

  @media (max-width: $size-mobile) {
    flex: 1 5%;
    align-self: flex-start;
    max-width: 50px;
    max-height: 50px;
  }
}

.mfeature_text {
  flex: 1 80%;
  align-self: center;

  @media (max-width: $size-mobile) {
    flex: 1 95%;
    align-self: flex-start;
  }
}

.mfeature--title {
  font-family: $font-face-rubik;
  font-size: $font-size-m;
  font-weight: 700;
  color: $color-purple-light;
  margin-bottom: 8px;

  @media (max-width: $size-mobile) {
    font-size: $font-root;
  }
}

.mfeature--text {
  font-family: $font-face-hind;
  font-size: $font-root;
  line-height: 1.3;
  color: $color-grey-breadcrumbs;
}

// Homepage Products
// ----------------------------------------------
.main_wrapper--products {
  display: flex;
  flex-wrap: wrap;
  padding: 72px 24px;
  justify-content: center;

  @media (max-width: $size-tablet) {
    padding: 56px 0;
  }
}

.title--products {
  flex: 1 100%;
  font-family: $font-face-rubik;
  font-size: $font-size-h1;
  font-weight: 300;
  color: $color-black;
  text-align: center;
  margin-bottom: 12px;

  @media (max-width: $size-tablet) {
    padding: 0 16px;
  }

  @media (max-width: $size-mobile-landscape) {
    font-size: $font-size-xl;
  }
}

.text--products {
  position: relative;
  flex: 1 100%;
  font-family: $font-face-hind;
  font-size: $font-size-l;
  line-height: 1.3;
  color: $color-grey-breadcrumbs;
  text-align: center;
  padding-bottom: 20px;
  margin-bottom: 48px;

  &::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 40px;
    height: 4px;
    background: $color-green;
  }

  @media (max-width: $size-tablet) {
    padding: 0 16px;
    padding-bottom: 20px;
  }

  @media (max-width: $size-mobile-landscape) {
    font-size: $font-size-m;
    line-height: 1.5;
    margin: 0 32px;
    margin-bottom: 48px;
  }
}

.mproducts_wrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  & + & {
    margin-top: 20px;
  }

  @media (max-width: $size-tablet-m) {
    & + & {
      margin-top: 0;
    }
  }
}

.mproducts_image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.mproducts_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.32);

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid transparent;
    transition: all 0.3s ease-in-out;
  }
}

.mproducts_text {
  position: absolute;
  bottom: 15%;
  left: 12%;
  color: $color-white;
  width: 80%;

  @media (max-width: $size-tablet-m) {
    bottom: 40%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }
}

.mproducts_text--center {
  bottom: 40%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;

  @media (max-width: $size-tablet-m) {
    bottom: auto;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

.mproducts_name {
  font-family: $font-face-rubik;
  font-size: $font-size-h2;
  font-weight: 700;
  margin-bottom: 8px;

  @media (max-width: $size-tablet-m) {
    font-size: $font-size-l;
    margin-bottom: 0;
  }

  @media (max-width: $size-mobile) {
    font-size: $font-size-m;
  }
}

.mproducts_category {
  font-family: $font-face-hind;
  font-size: $font-size-l;
  line-height: 1.5;

  @media (max-width: $size-tablet-m) {
    display: none;
  }
}

.mproducts {
  position: relative;
  flex: 1 30%;

  &::before {
    content: "";
    display: block;
    padding-top: (422 / 367) * 100%;
  }

  & + & {
    margin-left: 20px;
  }

  &:hover {
    .mproducts_overlay {
      &::before {
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%) scale(0.9);
        border-color: $color-white;
      }
    }
  }

  @media (max-width: $size-tablet-m) {
    flex: 1 33.33%;

    &::before {
      padding-top: (422 / 426) * 100%;
    }

    & + & {
      margin-left: 0;
    }

    &:hover,
    &:active {
      .mproducts_overlay {
        &::before {
          display: none;
        }
      }
    }
  }
}

.mproducts-m {
  flex: 1 35%;

  &::before {
    content: "";
    display: block;
    padding-top: (422 / 426) * 100%;
  }

  @media (max-width: $size-tablet-m) {
    flex: 1 33.33%;
  }
}

.mproducts-s {
  flex: 1 20%;

  &::before {
    content: "";
    display: block;
    padding-top: (282 / 285) * 100%;
  }

  @media (max-width: $size-tablet-m) {
    flex: 1 50%;
  }
}

// Homepage Customer
// ----------------------------------------------
.main_wrapper--customer {
  display: flex;
  flex-wrap: wrap;
  background-color: $color-purple-bg;
  padding: 60px 24px;
  justify-content: center;

  @media (max-width: $size-mobile-landscape) {
    padding: 40px 0;
  }
}

.text--customer {
  display: flex;
  flex-wrap: wrap;
  font-family: $font-face-rubik;
  font-size: 30px;
  align-items: center;
  margin-bottom: 24px;
  text-align: center;

  @media (max-width: 975px) {
    max-width: 500px;
    line-height: 1.5;
    background: url("/assets/images/circle_pattern.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  @media (max-width: $size-tablet-m) {
    font-size: $font-size-xl;
    font-weight: 300;
    justify-content: center;
    padding: 0 8px;
  }
}

.highlight--customer {
  height: 118px;
  background: url("/assets/images/circle_pattern.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  font-weight: 700;
  color: $color-purple-light;
  margin: 0 8px;

  span {
    border-bottom: 1px dashed $color-purple-light;
    line-height: 118px;
  }

  @media (max-width: 975px) {
    display: none;
  }
}

.highlight_customer--mobile {
  display: none;
  font-weight: 700;
  color: $color-purple-light;
  border-bottom: 1px dashed $color-purple-light;
  margin: 0 8px;
  text-align: center;

  @media (max-width: 975px) {
    display: inline;
  }

  @media (max-width: $size-tablet-m) {
    font-size: $font-size-xl;
  }
}

.inner_wrapper--customer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1060px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: $size-tablet-m) {
    padding: 0 16px;
  }
}

.hidden_wrapper--customer {
  max-height: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;

  .logo_wrapper--customer {
    height: 0;
  }
}

.wrapper--customer {
  display: flex;
  flex-wrap: wrap;
  max-height: 1000px;
  visibility: visible;
  opacity: 1;

  .logo_wrapper--customer {
    height: 100px;
  }
}

.logo_wrapper--customer {
  display: flex;
  flex: 1 16.6%;
  height: 100px;
  justify-content: center;

  img {
    align-self: center;
    max-height: 45px;
    max-width: 90px;
  }

  @media (max-width: $size-tablet-m) {
    flex: 1 33.33%;

    img {
      max-height: 35px;
      max-width: 80px;
    }
  }
}

// Homepage Order
// ----------------------------------------------
.main_wrapper--custom {
  background-color: $color-white;
  padding: 85px 24px;
  text-align: center;

  @media (max-width: $size-mobile) {
    padding-top: 40px;
  }
}

.title--custom {
  font-family: $font-face-rubik;
  font-size: $font-size-h1;
  font-weight: 500;
  color: $color-black;
  margin-bottom: 10px;

  @media (max-width: $size-mobile) {
    font-size: $font-size-xl;
  }
}

.separator--custom {
  max-width: 550px;
  width: 100%;
}

.text--custom {
  font-family: $font-face-hind;
  font-size: $font-size-l;
  color: $color-grey-breadcrumbs;
  line-height: 1.3;
  max-width: 500px;
  margin: 24px auto;
}

.button_wrapper--custom {
  width: 220px;
  margin: 0 auto;
}

// Banner
// ---------------------------------------
.slick-track {
  &:focus {
    outline: none;
  }
}

.slick-list {
  height: 100% !important;
}

.slick-arrow {
  width: 56px;
  height: 56px;
  z-index: $z-index-1;
}

.slick-prev {
  left: 32px;
  background: url("/assets/images/lchevron.svg");
  background-position: center;
  background-size: contain;

  &::before {
    content: "";
  }

  &:active,
  &:focus {
    background: url("/assets/images/lchevron.svg");
  }

  &:hover {
    background: url("/assets/images/lchevron.svg");
    opacity: 0.7;
  }

  @media (max-width: 1400px) {
    left: 20px;
  }

  @media (max-width: $size-tablet-m) {
    display: none !important;
  }
}

.slick-next {
  right: 32px;
  background: url("/assets/images/rchevron.svg");
  background-position: center;
  background-size: contain;

  &::before {
    content: "";
  }

  &:active,
  &:focus {
    background: url("/assets/images/rchevron.svg");
  }

  &:hover {
    background: url("/assets/images/rchevron.svg");
    opacity: 0.7;
  }

  @media (max-width: 1400px) {
    right: 20px;
  }

  @media (max-width: $size-tablet-m) {
    display: none !important;
  }
}

.slick-dots {
  display: none !important;
  bottom: 16px;

  li {
    width: 14px;
    height: 14px;
    margin: 0;
  }

  li button {
    width: 14px;
    height: 14px;
    padding: 0;

    &::before {
      width: 14px;
      height: 14px;
      font-size: 10px;
      color: rgba(0, 0, 0, 0.4);
      opacity: 1;
    }
  }

  li.slick-active button {
    &::before {
      color: $color-white;
      opacity: 1;
    }
  }

  @media (max-width: $size-tablet-m) {
    display: block !important;
  }
}
