@import "../../scss/variables/_index.scss";

.main_wrapper--banner {
  position: relative;
  width: 100%;
  margin-bottom: 32px;

  &::before {
    content: "";
    display: block;
    padding-top: (560 / 1440) * 100%;
  }
}

.banner_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.banner {
  position: relative;
  width: 100%;

  &::before {
    content: "";
    display: block;
    padding-top: (560 / 1440) * 100%;
  }
}

.banner_inner {
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;

  @media (max-width: 1400px) {
    max-width: 80%;
  }

  @media (max-width: $size-tablet-m) {
    max-width: 90%;
  }
}

.banner_text {
  flex: 1 40%;
  align-self: center;

  @media (max-width: $size-tablet-s) {
    flex: 1 100%;
    text-align: center;
  }
}

.banner--title {
  font-family: $font-face-rubik;
  font-size: $font-size-title;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 24px;

  @media (max-width: 980px) {
    font-size: $font-size-h1;
  }

  @media (max-width: $size-tablet-m) {
    font-size: $font-size-h3;
  }
}

.banner--text {
  font-family: $font-face-hind;
  font-size: $font-size-ml;
  font-weight: 300;
  line-height: 1.3;
  color: $color-grey-breadcrumbs;
  margin-bottom: 32px;

  @media (max-width: 980px) {
    font-size: $font-size-m;
  }

  @media (max-width: $size-tablet-s) {
    line-height: 1.5;
  }
}

.banner_link {
  display: flex;
  width: 215px;
  align-items: center;

  @media (max-width: $size-tablet-s) {
    display: none;
  }
}

.banner_wrapper--img {
  position: relative;
  flex: 1 50%;
  align-self: center;

  &::before {
    content: "";
    display: block;
    padding-top: (502 / 644) * 100%;
  }

  @media (max-width: $size-tablet-s) {
    flex: 1 100%;
  }
}

.banner_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
