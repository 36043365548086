// Product Page
// ===========================================
.product_container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: -16px;

  @media (max-width: $size-mobile) {
    margin-left: -10px;
  }
}

.product_hidden {
  @media (max-width: 720px) {
    display: none !important;
  }
}

.container_mobile-title {
  padding-top: 72px;
}

.product_mtitle {
  display: none;
  position: relative;
  font-family: $font-face-rubik;
  font-size: $font-size-l;
  font-weight: 700;
  margin-bottom: 8px;
  text-transform: uppercase;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 40px;
    height: 4px;
    background-color: $color-green;
  }

  @media (max-width: 720px) {
    display: block;
  }
}

.product_msubcategory {
  display: none;
  font-family: $font-face-rubik;
  font-size: $font-size-h3;
  font-weight: 300;
  color: $color-black;
  text-transform: uppercase;

  @media (max-width: 720px) {
    display: block;
  }
}
