@import "../../scss/variables/_index.scss";

$button-default-height: 64px;
$button-mobile-height: 50px;

// Button
// =================================================
button {
  background: transparent;
  border: none;
}

// Link
// =================================================
.link {
  background: none;
  border: none;
  font-weight: 500;
  color: $color-dark-grey;
  padding: 0;
  margin: 0;

  > span {
    border-bottom: 1px dashed transparent;
  }

  &:hover {
    cursor: pointer;
    color: $color-purple-light;

    > span {
      border-bottom: 1px dashed $color-purple-light;
    }

    svg {
      color: $color-purple-light;
    }
  }

  &:focus {
    outline: none;
  }
}

.link_active {
  color: $color-purple-light;

  > span {
    border-bottom: 1px dashed $color-purple-light;
  }

  svg {
    color: $color-purple-light;
  }
}

.link_menu {
  display: flex;
  height: 100%;
  align-items: center;
}

.link_hamburger {
  border: none;
  background: none;

  &:focus {
    outline: none;
  }
}

.link_submenu {
  cursor: pointer;
  display: block;
  font-family: $font-face-hind;
  font-size: $font-size-m;
  font-weight: 300;
  color: $color-dark-grey;

  &:hover {
    cursor: pointer;
  }
}

.link-grey {
  color: $color-black;

  &:hover {
    color: $color-purple-light;
  }
}

.link_wrapper-client {
  display: flex;
  flex: 1 100%;
  justify-content: center;
}

.link-client {
  display: inline-block;
  height: 40px;
  text-align: center;
  font-family: $font-face-rubik;
  font-size: $font-size-m;
  color: #5c5c5c;

  &:hover {
    cursor: pointer;
  }
}

// Button
// =================================================
.button {
  display: flex;
  flex: 1 100%;
  width: 100%;
  height: $button-default-height;
  font-family: $font-face-rubik;
  font-size: $font-size-l;
  font-weight: 700;
  color: $color-white;
  text-transform: uppercase;
  letter-spacing: 1.1px;
  padding: 16px 24px;
  background-color: $color-purple-light;
  border-radius: 4px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.28);
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    background-color: $color-link-purple;
  }

  &:focus {
    background-color: $color-link-purple;
  }

  &:active {
    background-color: $color-link-purple;
  }

  &:active,
  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: $color-grey-bg;
  }

  @media (max-width: $size-mobile) {
    height: $button-mobile-height;
    font-size: $font-root;
  }
}

.button--wa {
  position: fixed;
  bottom: 40px;
  right: 32px;
  width: 90px;
  height: 90px;
  z-index: 5;

  @media (max-width: $size-mobile) {
    right: 24px;
    height: 75px;
    width: 75px;
  }
}

.wa__icon {
  width: 100%;
  height: 100%;
}
