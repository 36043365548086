@import "../../scss/variables/_index.scss";

.breadcrumbs_wrapper {
  display: flex;
  width: 100%;
  align-items: flex-start;
  margin-bottom: 18px;
}

.breadcrumbs {
  display: flex;
  font-family: $font-face-hind;
  font-size: $font-root;
  line-height: 1.2;

  & + & {
    &::before {
      content: "";
      display: flex;
      align-self: flex-start;
      width: 10px;
      height: 14px;
      background: url("/assets/images/bc_right.svg");
      background-size: 8px;
      background-repeat: no-repeat;
      margin: 0 16px;
    }
  }
}

.breadcrumbs_link {
  color: $color-grey-breadcrumbs;

  &:hover,
  &:focus {
    color: $color-purple-light;
  }

  &:active {
    color: $color-grey-breadcrumbs;
  }
}

.breadcrumbs_link-active {
  pointer-events: none;
  color: $color-grey-breadcrumbs;

  &:hover,
  &:focus {
    color: $color-grey-breadcrumbs;
  }
}
