$font-face-rubik: "Rubik", Arial, Helvetica, sans-serif;
$font-face-hind: "Hind Madurai", Arial, Helvetica, sans-serif;
$font-face-open: "Open Sans", Arial, Helvetica, sans-serif;

$font-root: 14px;

$font-size-xs: (10 / 14) * $font-root;
$font-size-s: (12 / 14) * $font-root;
$font-size-m: (16 / 14) * $font-root;
$font-size-l: (18 / 14) * $font-root;
$font-size-ml: (21 / 14) * $font-root;
$font-size-xl: (24 / 14) * $font-root;

$font-size-title: (52 / 14) * $font-root;
$font-size-h1: (40 / 14) * $font-root;
$font-size-h2: (32 / 14) * $font-root;
$font-size-h3: (28 / 14) * $font-root;
