@import "../../scss/variables/_index.scss";

.modal__body {
  position: relative;
  background: $color-white;
  width: 100%;
  max-height: 100vh;
  min-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.form--order {
  display: flex;
  padding-top: 100px;
  padding-bottom: 72px;
  max-width: 1200px;
  margin: 0 auto;
}

.formo_wrapper-img {
  position: relative;
  flex: 1 45%;

  &::before {
    content: "";
    display: block;
    padding-top: (420 / 406) * 100%;
  }

  @media (max-width: $size-tablet-m) {
    flex: 1 35%;
  }

  @media (max-width: $size-tablet-sm) {
    display: none;
  }
}

.formo_img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
}

.formo_wrapper {
  flex: 1 55%;
  padding-left: 48px;
  padding-right: 32px;
  box-sizing: border-box;

  @media (max-width: $size-tablet-m) {
    flex: 1 65%;
  }

  @media (max-width: $size-tablet-sm) {
    flex: 1 100%;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    padding: 0 32px;
  }
}

.formo_title {
  position: relative;
  font-family: $font-face-rubik;
  font-size: $font-size-h1;
  font-weight: 700;
  color: $color-black;
  margin-bottom: 40px;

  &::after {
    content: "";
    position: absolute;
    bottom: -8px;
    left: 0;
    width: 40px;
    height: 4px;
    background: $color-green;
  }
}

.formo_desc {
  font-family: $font-face-hind;
  font-size: $font-size-m;
  line-height: 1.5;
  color: $color-grey-breadcrumbs;
  max-width: 400px;
}
