@import "../../scss/variables/_index.scss";

.accordion_wrapper {
  border-top: 1px solid #d8d8d8;
  padding-top: 16px;
}

.accordion_wrapper--title {
  display: flex;
  width: 100%;
  font-family: $font-face-rubik;
  font-size: $font-size-l;
  font-weight: 500;
  line-height: 1.3;
  color: $color-purple-light;
  padding: 0;

  &:active,
  &:focus {
    outline: none;
  }
}

.accordion_title {
  flex: 1 90%;
  text-align: left;
}

.detail_accordion {
  font-size: $font-size-m;
  font-weight: 700;
  color: $color-black;
  padding-bottom: 24px;
  padding-top: 8px;
}

.accordion_icon {
  flex: 1 10%;
  text-align: right;
  transition: transform 0.3s ease-in-out;
}

.accordion_content {
  max-height: 0;
  visibility: hidden;
  transition: max-height 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  padding: 0;

  p,
  ul,
  ol {
    max-height: 0;
  }
}

.detail_accordion-content {
  border-top: 1px solid #d8d8d8;

  div {
    font-family: $font-face-rubik;
    font-size: $font-size-m;
    font-weight: 500;
    color: $color-purple-light;
  }

  p,
  ul,
  li {
    font-family: $font-face-hind;
    font-size: $font-size-m;
    line-height: 1.5;
    color: $color-grey-breadcrumbs;
  }
}

.accordion_show {
  .accordion_icon {
    transform: rotate(-45deg);
    transform-origin: 80%;

    svg {
      font-size: 17px;
    }
  }

  .accordion_content {
    max-height: none;
    visibility: visible;
    padding-top: 24px;
    padding-bottom: 24px;

    p,
    ul,
    ol {
      max-height: none;
    }
  }
}

.detail_accordion-img {
  width: 100%;
}
