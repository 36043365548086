@import "../../scss/variables/_index.scss";

.menu_wrapper {
  position: fixed;
  display: flex;
  top: 80px;
  left: 0;
  width: 100%;
  background: $color-white;
  box-shadow: 0 12px 14px 0 rgba(0, 0, 0, 0.05);
  z-index: $z-index-3;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);

  @media (max-width: $size-desktop) {
    padding: 0 16px;
  }

  @media (max-width: $size-tablet) {
    display: none;
  }
}

.menu_open {
  opacity: 1;
  visibility: visible;
}

.menu_inner {
  flex: 1 25%;
  padding: 32px 0;
}

.menu_icon {
  max-width: 43px;
  margin-bottom: 10px;
}

.menu_title {
  font-family: $font-face-hind;
  font-size: $font-size-m;
  font-weight: 700;
  color: $color-purple-light;
  text-transform: uppercase;
}

.menu_list {
  list-style-type: none;
  padding: 0;
  font-family: $font-face-hind;
  font-size: $font-size-m;
  color: $color-black;

  li + li {
    margin-top: 12px;
  }
}

// Mobile Menu
// ===============================================
.mmenu_wrapper {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  padding: 72px 0;
  padding-bottom: 24px;
  background-color: $color-white;
  z-index: $z-index-10;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}

.mmenu_wrapper--show {
  transform: translateX(0);
}

.mmenu_wrapper--inner {
  display: block;
  overflow: scroll;
  height: 100%;
  padding-bottom: 72px;
}

.mmenu_title {
  font-family: $font-face-rubik;
  font-size: $font-size-xl;
  font-weight: 700;
  padding: 0 24px;
  margin-bottom: 48px;

  span {
    padding-bottom: 4px;
    border-bottom: 4px solid $color-green;
  }
}

.mmenu_list {
  list-style-type: none;
  font-family: $font-face-rubik;
  padding: 0;
  margin: 0;
}

.mmenu_list--inner {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border-top: 1px solid $color-light-grey;
  visibility: hidden;
  opacity: 0;
  height: 0;
  transition: all 0.3s ease-in-out, opacity 0.1s ease-in-out;
}

.mmenu_list--open {
  margin: 12px 0;
  height: auto;
  visibility: visible;
  opacity: 1;
}

.mmenu {
  & + & {
    margin-top: 10px;
  }
}

.mmenu_link {
  position: relative;
  display: block;
  width: 100%;
  font-size: $font-size-m;
  font-weight: 500;
  color: $color-grey-breadcrumbs;
  padding: 18px 28px;
  text-align: left;
  background-color: transparent;
  border: none;

  &:hover {
    color: darken($color-grey-breadcrumbs, 20);
  }
}

.msubmenu {
  display: block;
  font-family: $font-face-hind;
  border-bottom: 1px solid $color-light-grey;

  & + & {
    margin-top: 10px;
  }
}

.msubmenu_link {
  position: relative;
  display: block;
  width: 100%;
  background: transparent;
  font-size: $font-root;
  font-weight: 500;
  color: $color-purple-menu;
  padding: 18px 28px;
  text-align: left;
  text-transform: uppercase;
  border: none;

  &:hover {
    color: lighten($color-purple-menu, 20);
  }
}

.mmenu_icon {
  position: absolute;
  top: 50%;
  right: 28px;
  transform: translateY(-50%);
  font-size: $font-root;
  transition: transform 0.3s ease-in;
}

.mmenu_icon--open {
  transform: translateY(-50%) rotate(180deg);
}

.msubmenu_icon--open {
  transform: translateY(-50%) rotate(-45deg);
}

.msubcategory_list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-family: $font-face-hind;
  visibility: hidden;
  opacity: 0;
  height: 0;
  transition: all 0.3s ease-in-out, opacity 0.1s ease-in-out;
}

.msubcategory_list--open {
  margin: 12px 0;
  height: auto;
  visibility: visible;
  opacity: 1;
}

.msubcategory {
  margin-bottom: 10px;
}

.msubcategory_link {
  display: block;
  font-size: $font-root;
  padding: 18px 28px;
  color: $color-dark-grey;
}

.mmenu_close {
  position: absolute;
  top: 24px;
  right: 16px;
  background: transparent;
  border: none;
  box-shadow: none;
}

.mmenu_close--icon {
  width: 32px;
  height: 32px;
  font-size: 24px;
}
