@import "../../scss/variables/_index.scss";

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  height: 80px;
  background: $color-white;
  font-family: $font-face-rubik;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.06);
  z-index: $z-index-5;

  @media (max-width: $size-mobile) {
    height: 60px;
  }
}

.header_logo-container {
  display: flex;
  flex: 1 50%;
  height: 100%;
  align-items: center;

  @media (max-width: $size-desktop) {
    flex: 1 30%;
    padding: 0 16px;
  }
}

.header_logo {
  max-width: 185px;

  @media (max-width: $size-tablet-s) {
    max-width: 150px;
  }

  @media (max-width: $size-mobile) {
    max-width: 120px;
  }
}

.header_hamburger-wrapper {
  display: none;
  flex: 1 40%;
  align-items: center;
  justify-content: flex-end;
  padding-right: 16px;

  @media (max-width: $size-tablet) {
    display: flex;
  }
}

.header_hamburger {
  font-size: $font-size-ml;
}

.header_menu-wrapper {
  flex: 1 50%;

  @media (max-width: $size-desktop) {
    flex: 1 70%;
    padding: 0 16px;
  }

  @media (max-width: $size-tablet) {
    display: none;
  }
}

.header_menu-list {
  display: flex;
  justify-content: flex-end;
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-size: $font-root;
  font-weight: 500;
  color: $color-dark-grey;
  height: 100%;
  align-items: center;
}

.header_menu {
  display: flex;
  height: 100%;
  align-items: center;

  & + & {
    margin-left: 42px;
  }

  svg {
    font-size: $font-size-s;
    margin-left: 10px;
  }
}

// Product Menu
// =========================================================
