.details_image-wrapper {
  flex: 1 40%;
  width: 40%;

  @media (max-width: $size-tablet-sm) {
    flex: 1 100%;
    width: 100%;
  }
}

.details_share {
  display: flex;
  align-items: center;
  font-family: $font-face-hind;
  font-size: $font-root;
  color: $color-grey-breadcrumbs;

  @media (max-width: $size-tablet-sm) {
    padding: 0 16px;
  }
}

.details_share-link {
  flex: 1 33.33%;
  align-items: center;
  margin-left: 8px;
}

.details_wrapper {
  flex: 1 60%;
  padding: 0 20px;
  padding-left: 56px;

  @media (max-width: $size-tablet-sm) {
    flex: 1 100%;
    padding-left: 20px;
  }
}

.product_details-name {
  font-family: $font-face-rubik;
  font-size: $font-size-title;
  font-weight: 700;
  margin: 16px 0;

  @media (max-width: $size-tablet) {
    font-size: $font-size-h1;
  }

  @media (max-width: $size-tablet-sm) {
    font-size: $font-size-h2;
  }
}

.product_details-desc {
  font-family: $font-face-rubik;
  font-size: $font-size-m;
  font-weight: 300;
  line-height: 1.5;
  padding-bottom: 32px;
}

.product_detail-accordion {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-content: center;
  padding: 0;
}

.button_wrapper-order {
  width: 250px;
  margin-top: 40px;

  @media (max-width: $size-tablet-sm) {
    position: fixed;
    bottom: 0;
    left: 0;
    background: $color-white;
    padding: 16px;
    width: 100%;
    z-index: $z-index-10;
  }
}

.related_products-title {
  position: relative;
  font-family: $font-face-rubik;
  font-size: $font-size-h1;
  font-weight: 500;
  text-align: center;
  padding-bottom: 16px;
  color: $color-black;

  &::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 40px;
    height: 4px;
    background: $color-green;
  }

  @media (max-width: $size-tablet-sm) {
    font-size: $font-size-h3;
  }
}

.related_container {
  position: relative;
  display: flex;
  margin-left: -16px;

  @media (max-width: $size-tablet-sm) {
    display: block;
    white-space: nowrap;
    overflow-x: scroll;
  }
}

.related_link {
  flex: 1 100%;
  text-align: center;
}

.seemore_link {
  font-family: $font-face-rubik;
  font-size: $font-size-l;
  font-weight: 500;
  color: $color-purple-light;
  padding-bottom: 4px;
  border-bottom: 1px dashed $color-purple-light;
}

// Slider
.slider_wrapper {
  .slick-list {
    position: relative;
  }
}

.slider_thumb {
  display: block;
  list-style-type: none;
  padding: 0;
  white-space: nowrap;
  overflow: auto;

  li {
    position: relative;
    display: inline-block;
    width: 25%;

    &::before {
      content: "";
      display: block;
      padding-top: (70 / 70) * 100%;
    }
  }

  li + li {
    margin-left: 20px;
  }
}

.slider_thumb-img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
}
