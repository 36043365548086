@import "../../scss/variables/_index.scss";

footer {
  position: relative;
  background: $color-purple-dark;
  padding-top: 50px;
  padding-bottom: 50px;
  font-family: $font-face-hind;
}

.footer_container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  color: $color-white-footer;

  @media (max-width: $size-tablet-s) {
    padding: 0 20px;
  }
}

.footer_container-inner {
  display: relative;
  flex: 1 47.5%;
  align-content: center;
  padding-right: 40px;

  @media (max-width: $size-mobile) {
    flex: 1 100%;
    padding-right: 0;
    padding: 24px 16px;
  }
}

.footer_separator {
  flex: 1 5%;
  position: relative;

  &::before {
    content: "";
    display: inline-block;
    width: 1px;
    height: 276px;
    background: #2d2260;
  }

  & + .footer_container-inner {
    padding-right: 0;
    padding-left: 10px;
  }

  @media (max-width: $size-mobile) {
    display: none;

    & + .footer_container-inner {
      border-top: 1px solid #2d2260;
    }
  }
}

// Footer Left
// =============================================
.footer_logo {
  flex: 1;
  margin-bottom: 28px;
}

.footer_logo-img {
  width: 100%;
  max-width: 185px;
}

.footer_cp-name {
  font-size: $font-size-l;
  font-weight: 700;
  margin-bottom: 10px;
}

.footer_cp-address {
  font-size: $font-size-m;
  font-weight: 300;
  line-height: 1.38;
  margin-bottom: 32px;
}

.footer_map {
  font-size: $font-size-s;
  font-weight: 500;
  color: $color-purple;
  letter-spacing: 0.9px;

  span {
    vertical-align: middle;
  }
}

.footer_map-icon {
  display: inline-block;
  vertical-align: center;
  font-size: $font-size-m;
  line-height: 0.8;
  margin-right: 4px;
}

// Footer Right
// =========================================
.footer_title {
  font-size: $font-size-m;
  font-weight: 700;
  color: $color-green;
  margin-bottom: 16px;
}

.footer_contacts {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer_contacts-info {
  display: flex;
  align-items: center;
  font-size: $font-size-m;
  font-weight: 300;

  & + & {
    margin-top: 16px;
  }
}

.contacts_info-top {
  align-items: flex-start;
}

.footer_contacts-multi {
  margin-top: 8px;
}

.footer_contacts-icon {
  font-size: $font-size-l;
  line-height: 0.8;
  margin-right: 16px;
}

// Footer Bottom
// =============================================
.footer_ft {
  flex: 1;
  text-align: center;
  margin-top: 32px;
}

.footer_links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 12px;

  a + a {
    margin-left: 24px;
  }
}

.footer_link {
  color: $color-purple;
}

.footer_socmed {
  display: flex;
  position: relative;
  width: 28px;
  height: 28px;
  background: $color-white;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  fill: $color-black;
}

.footer_links-icon {
  font-size: 28px;
  line-height: 0.7;
  fill: $color-black;
  align-self: center;
}

.footer_links-icon--sm {
  font-size: 16px;
}

.footer_copy {
  font-size: $font-root;
  color: $color-cp-footer;
}

// Footer Ornament
// ==========================================
.footer_ornament {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 6px;
}

.footer_ornament-green {
  flex: 1 50%;
  height: 100%;
  background: $color-green;
}

.footer_ornament-purple {
  flex: 1 50%;
  height: 100%;
  background: $color-purple-light;
}
